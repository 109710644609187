import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {screenSize, colors} from '../../constants/styleConstants';
import {TextLink} from '@snsw/react-component-library';

export const Container = styled.div`
     max-width: ${screenSize.desktop};
     width: 100%;
     margin-left: auto;
     margin-right: auto;
     padding: 0px;
`;

export const CountField = styled.p`
    line-height: 1.5rem;
    margin-top: 1.5rem;
    margin-left: inherit;
    margin-bottom: 2rem;
`;

export const InboxListItem = styled.div`
  background-color: ${props => props.isUnread ? '#EFF8FB' : 'transparent'};
  padding-bottom: 1.5rem;
  border-top: 2px #bdcbd2 solid;
`;

export const SenderField = styled.p`
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0;
    margin-left: 1.5rem;
    font-weight: bold;
    color: ${colors.grey.shuttle};
`;

export const TimestampField = styled.p`
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 1rem;
    margin-bottom: 0;
    margin-right: 1.5rem;
    color: ${colors.grey.shuttle};
    text-align: right;
    @media only screen and (max-width: ${screenSize.tablet}) {
        text-align: left;
        margin-left: 1.5rem;
        margin-top: 0.5rem;
    }
`;

export const ContentField = styled.p`
    line-height: 1.5rem;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    margin-bottom: 0px;
`;

export const CustomisedDotIcon = styled(FontAwesomeIcon)`
    font-size: 0.625rem;
     @media only screen and (max-width: ${screenSize.tablet}) {
        margin-left: -1.125rem;
    }
`;

export const UnreadContent = styled(TextLink)`
    color: ${colors.brand.snswSecondaryBlue}
    line-height: 1.5rem;
    margin-top: 0.625rem;
    margin-left: 0.5rem;
    margin-bottom: 0px;
`;

