import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from '@snsw/react-component-library';
import TextLink from '@snsw/react-component-library/build/Components/TextLink/TextLink';
import {Document} from '../document/Document';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {colors} from '../../constants/styleConstants';
import {
    ContentField,
    CustomisedDotIcon,
    InboxListItem,
    SenderField, TimestampField,
    UnreadContent
} from './ListItem.styled';
import { useNavigate } from 'react-router-dom';

function redirectToInboxDetailPage(event, navigate, notificationId){
    event.preventDefault();

    navigate({
        pathname: `/notifications/${notificationId}`
    });
}

export const ListItem = ({notification}) => {

    const navigate = useNavigate();

    return <InboxListItem id={ notification.notificationId } isUnread={ notification.hasShortMessage && !notification.read }>
        <Row key={ notification.notificationId }>
            <Col span={ 8 }><SenderField>{notification.sender}</SenderField></Col>
            <Col span={ 4 }><TimestampField> {notification.timestamp}</TimestampField></Col>
        </Row>
        <Row>
            <Col span={ 12 }>
                <ContentField>
                    {notification.hasShortMessage ?
                        <>
                            {notification.read ?
                                <TextLink style={ {color: colors.grey.darkGrey} } href='#'
                                          aria-label={ 'Opened item: ' + notification.content }
                                    onClick={ (e) => redirectToInboxDetailPage(e, navigate, notification.notificationId) }>
                                    {notification.content}
                                </TextLink>
                                :
                                <><CustomisedDotIcon icon={ faCircle } aria-hidden='true'
                                    color={ colors.brand.snswSecondaryBlue } title='Unread item'/>
                                <UnreadContent href='#'
                                    aria-label={ 'Unopened item: ' + notification.content }
                                    onClick={ (e) => redirectToInboxDetailPage(e, navigate, notification.notificationId) }>
                                    <strong>{notification.content}</strong>
                                </UnreadContent></>
                            }
                        </> : <>{notification.content}</>
                    }
                </ContentField>
                {(notification.documents.length > 0) && <Document notification={ notification }/>}
            </Col>
        </Row>
    </InboxListItem>;
};

ListItem.propTypes = {
    notification: PropTypes.object,
    currentPage: PropTypes.number
};

