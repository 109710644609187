import React, {useContext, useEffect, useState} from 'react';
import {apiServices} from '../../services/api-services';
import {
    AlertNotification,
    Container,
    CountField,
    InboxHeadingContainer,
    PreferencesButton,
    Separator
} from './InboxPage.styled';
import {ComponentLoader, Heading} from '@snsw/react-component-library';
import {ListItem} from '../listItem/ListItem';
import {useNavigate} from 'react-router-dom';
import {CurrentPageContext} from '../CurrentPageContext';
import Pagination from '../pagination/Pagination';

export const InboxPage = () => {
    const [inboxList, setInboxList] = useState([]);
    const [pageErrors, setPageErrors] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [unreadCount, setUnreadCount] = useState(null);
    const [activeLoader, setActiveLoader] = useState(true);
    const {currentPage, setCurrentPage} = useContext(CurrentPageContext);
    const navigate = useNavigate();
    const pageSize = 10;

    const changeHandler = (pageNo) => {
        setCurrentPage(pageNo);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', pageNo);
        const destinationPath = `${location.pathname}?${searchParams.toString()}`;
        window.history.pushState({}, '', destinationPath);
    };

    function redirectToPreferencesPage() {
        navigate('/manage/notification-preferences');
        window.location.reload();
    }

    useEffect(() => {
        apiServices.getInboxListByPage(currentPage, pageSize)
            .then(({data}) => {
                setPageErrors(data.errors);
                const notificationListData = data.notificationsList;
                const totalCountData = data.totalCount || notificationListData.length;
                const unreadCountData = data.unreadCount;
                if (currentPage > Math.ceil(totalCountData / pageSize)) {
                    changeHandler(1);
                }
                setInboxList(notificationListData);
                setTotalCount(totalCountData);
                setUnreadCount(unreadCountData);
                setActiveLoader(false);
                scrollToTop();
            })
            .catch(() => {
                setPageErrors([{
                    type: 'ERROR',
                    errorType: 'BFF_DOWN_ERROR',
                    title: 'We were unable to load this page',
                    description: 'To view your inbox, please try again later.'
                }]);
                setActiveLoader(false);
            });
    }, [currentPage]);

    const scrollToTop = () => {

        window.scrollTo({
            top: 0
        });
    };

    const renderedInboxList = inboxList.map(notification => {
        return (
            <ListItem key={ notification.notificationId } notification={ notification } currentPage={ currentPage }/>);
    });

    const renderUnreadCountField = (unreadCount) => {
        const unreadStr = unreadCount === 1 ? `${unreadCount} unread notification` : `${unreadCount} unread notifications`;
        return <CountField role='status' data-testid='unread-total-count'> {unreadStr}</CountField>;
    };

    return (
        <Container role='main'>
            <div style={ {marginLeft: 0, marginRight: 0} }>
                <InboxHeadingContainer>
                    <Heading data-testid={ 'inbox-page-heading' } level={ 1 }>Inbox</Heading>
                    <PreferencesButton variant='secondary' data-testid='preferences-redirect-button'
                        aria-label={ 'Notification Preferences' }
                        onClick={ () => {
                            redirectToPreferencesPage();
                        } }>Preferences
                    </PreferencesButton>
                </InboxHeadingContainer>
                {pageErrors && pageErrors.map(e => {
                    return <AlertNotification key={ e.title } headingLevel='2' style={ {marginBottom: 0} }
                        variant='error'
                        title={ e.title }
                        role='alert'>
                        <p>{e.description}</p>
                    </AlertNotification>;
                })
                }
                <Container role='region'>
                    <ComponentLoader fullPage active={ activeLoader }/>
                    {(unreadCount != null && totalCount != null) && renderUnreadCountField(unreadCount)}
                    {renderedInboxList}
                    <Separator/>
                </Container>
            </div>
            <div id='pagination-section' style={ {marginTop: '1.5rem'} } >
                <Pagination totalItems={ totalCount } currentPage={ currentPage }
                    pageSize={ pageSize } onPageChange={ (pageNo) => {
                        changeHandler(pageNo);
                    } }/>
            </div>
        </Container>
    );
};