export const screenSize = {
    mobile: '480px',
    tablet: '768px',
    desktop: '1224px',
};

export const colors = {
    brand: {
        snswPrimaryBlue: '#002664',
        snswPrimaryRed: '#d7153a',
        snswSecondaryBlue: '#2e5299',
        snswTertiaryBlue: '#0a7cb9',
        snswDarkGrey: '#242934'
    },
    white: '#ffffff',
    black: '#000000',
    grey: {
        darkGrey: '#242934',
        shuttle: '#646974',
        geyser: '#dee3e5',
        mystic: '#ecf1f3',
        iceBlue: '#f4f7f9'
    }
};