import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
export const CurrentPageContext = createContext(1);
export function CurrentPageProvider({ children, ...props }) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const initialPage = parseInt(params.get('page')) || 1;

    const [currentPage, setCurrentPage] = useState(initialPage);
    return (
        <div { ...props }>
            <CurrentPageContext.Provider
                value={ {
                    currentPage, setCurrentPage, initialPage
                } }>
                {children}
            </CurrentPageContext.Provider>
        </div>
    );
}

CurrentPageProvider.propTypes = {
    children: PropTypes.any
};

