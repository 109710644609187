import {
    BreadcrumbContainer,
    BreadcrumbList, BreadcrumbListItem
} from '@snsw/react-component-library/build/Components/Breadcrumb/Breadcrumb.styled';
import {IconChevronLeft, IconChevronRight} from '@snsw/react-component-library/build/Icons/system';
import TextLink from '@snsw/react-component-library/build/Components/TextLink/TextLink';
import React from 'react';
import PropTypes from 'prop-types';

// Added this breadcrumb component to leverage Kiama component as Kiama does not yet support react-router-dom v6
// so there will be error showing on notification details screen
export const Breadcrumb = ({notificationTitle}) => {
    return (
        <BreadcrumbContainer data-testid='inbox-detail-page-breadcrumb'
            style={ {marginTop: '1.5rem', marginBottom:'2.5rem'} }
            aria-label='Breadcrumb'>
            <IconChevronLeft />
            <BreadcrumbList>
                <BreadcrumbListItem>
                    <TextLink data-testid='inbox-link' href='/notifications' style={ {textDecoration: 'underline' } }>Inbox</TextLink>
                    <IconChevronRight/>
                </BreadcrumbListItem>
                <BreadcrumbListItem>
                    <p style={ {fontSize: '0.875rem', fontWeight: 'bold'} } aria-current={ 'page' }>{notificationTitle ??
                        'Notification Details'}</p>
                </BreadcrumbListItem>
            </BreadcrumbList>
        </BreadcrumbContainer>
    );
};

Breadcrumb.propTypes = {
    notificationTitle: PropTypes.string
};
