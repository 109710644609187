import {formatBytes} from '../../utilities/utilities';
import {Col, IconDownload, IconExternalLink, Row} from '@snsw/react-component-library';
import React from 'react';
import {DocumentField} from './Document.styled';

function createDocumentField(baseUrl, doc){

    let target = '_self';
    let documentIcon = <IconDownload color='secondaryBlue' style={ {marginRight: '0.5rem', marginLeft: 0} }/>;
    let docFileName = '';

    const docHref = baseUrl + doc.index;
    const docSize = formatBytes(doc.size, 0);
    const isEmptyDocName = doc.name == null || doc.name.trim() === '';

    if (doc.type === 'LINK' && isEmptyDocName) {
        target = '_blank';
        documentIcon = <IconExternalLink color='secondaryBlue' style={ {marginRight: '0.5rem', marginLeft: 0} }/>;
        docFileName = 'View details';
    }else if (doc.type !== 'LINK' && isEmptyDocName) {
        docFileName = docSize == null ? 'Download attachment' : ('Download attachment (' + docSize + ')');
    }else{
        const separatorIndex = doc.name.lastIndexOf('.');
        docFileName = doc.name.substring(0, separatorIndex);
        const docFileType = doc.name.substring(separatorIndex + 1).toUpperCase();
        if (docFileName.toLowerCase().startsWith('download')) {
            docFileName = docFileName.replace(docFileName.charAt(0), 'D') + ' (' + docFileType;
        } else {
            docFileName = 'Download ' + docFileName + ' (' + docFileType;
        }
        docFileName = docSize == null ? (docFileName + ')') : (docFileName + ' ' + docSize + ')');
    }

    return (
        <DocumentField href={ docHref } target={ target }>
            {documentIcon}{docFileName}
        </DocumentField>
    );
};

export const Document = ({notification}) => {
    const baseUrl = '/api/notification-list/documents?notificationId=' + notification.notificationId + '&index=';
    return (notification.documents.map((doc) => {

        return (<Row key={ doc.index }>
            <Col span={ 12 } style={ {marginTop: '0.5rem'} }>
                {createDocumentField(baseUrl, doc)}
            </Col>
        </Row>);
    }));
};
