export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0 | bytes == null) {
        return null;
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getTagManagerArgs(event, componentType, sectionId, positionId, componentText, destinationUrl) {
    return {
        dataLayer: {
            'event': event,
            'component_type': componentType,
            'section_id': sectionId,
            'position_id': positionId,
            'component_text': componentText,
            'destination_url': destinationUrl
        }
    };
}