import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {apiServices} from '../../services/api-services';
import {AlertNotification, Container} from '../inboxPage/InboxPage.styled';
import {InboxDetailHeading, Sender, Timestamp} from './InboxDetailPage.styled';
import {useNavigate} from 'react-router-dom';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import {Breadcrumb} from '../breadcrumb/Breadcrumb';
import DOMPurify from 'dompurify';
import {CurrentPageContext} from '../CurrentPageContext';

export const sanitizer = DOMPurify.sanitize;
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if (node.nodeName === 'A')  {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
    }
});

const InboxDetailPage = () => {
    const { currentPage } = useContext(CurrentPageContext);
    const params = useParams();
    const navigate = useNavigate();

    const [inboxDetailData, setInboxDetailData] = useState({});
    const [pageErrors, setPageErrors] = useState([]);

    useEffect(() => {

        const prevTitle = document.title;

        apiServices.getInboxDetailData(params)
            .then(r => {
                console.log(r);
                setInboxDetailData(r.data);
                document.title = `${r.data.title} |  MyServiceNSW Account`;
            })
            .catch(() => {
                setPageErrors([{
                    type: 'ERROR',
                    errorType: 'UNFOUND_NOTIFICATION_ERROR',
                    title: 'Error',
                    description: 'The system is unable to find details for your notification'
                }]);
            });

        window.scroll({
            top: 0,
            left: 0
        });

        return () => {
            document.title = prevTitle;
        };

    }, []);

    return (
        <Container role='main'>
            <Breadcrumb notificationTitle = { inboxDetailData.title }/>
            {pageErrors && pageErrors.map(e => {
                return <AlertNotification key={ e.title } headingLevel='2' style={ {marginBottom: 0} }
                    variant='error'
                    title={ e.title }
                    role='alert'>
                    <p>{e.description}</p>
                </AlertNotification>;
            })
            }
            <InboxDetailHeading level={ 1 } style={ {marginTop: '0px' } } data-testid='inbox-detail-page-heading'>{ inboxDetailData.title }</InboxDetailHeading>
            <Sender data-testid='inbox-detail-page-sender'>{ inboxDetailData.sender }</Sender>
            <Timestamp data-testid='inbox-detail-page-timestamp'>{ inboxDetailData.timestamp }</Timestamp>
            <div data-testid='inbox-detail-page-message' dangerouslySetInnerHTML={ {__html: sanitizer(inboxDetailData.message, {FORCE_BODY: true}) } }/>
            <Button role='button' variant='secondary' data-testid='back-button' style={ {marginTop: '17px', marginBottom: '56px'} }
                onClick={ () => navigate(`/notifications?page=${currentPage}`, { state: { currentPage } }) }>Back
            </Button>
        </Container>
    );
};

export default InboxDetailPage;