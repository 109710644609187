import styled from 'styled-components';
import {screenSize} from '../../constants/styleConstants';
import {InPageAlert} from '@snsw/react-component-library';
import Button from '@snsw/react-component-library/build/Components/Button/Button';

export const Container = styled.div`
     max-width: ${screenSize.desktop};
     width: 100%;
     margin-left: auto;
     margin-right: auto;
     padding: 0px;
`;

export const CountField = styled.p`
    line-height: 1.5rem;
    margin-top: -1rem;
    margin-left: inherit;
    margin-bottom: 2rem;
    @media only screen and (max-width: ${screenSize.tablet}) {
        margin-top: 0.25rem;
    }
`;

export const AlertNotification = styled(InPageAlert)`
    margin-bottom: 0;
    margin-top: 1.8rem;
`;

export const InboxHeadingContainer = styled.div`
    max-width: ${screenSize.desktop};
    margin-left: auto;
    margin-top: 0rem;
    margin-bottom: 0rem;   
    margin-right: auto;
    display: flex;
    width: 100%;
    align-items: baseline; 
    justify-content: space-between;
    @media only screen and (max-width: ${screenSize.tablet}) {
        margin-top: 1rem;
    }
`;

export const PreferencesButton = styled(Button)`
    margin-left: auto;
    @media only screen and (max-width: ${screenSize.mobile}) {
        width: 50%;
    }
`;

export const Separator = styled.div`
    border-top: 2px #bdcbd2 solid;
    height: 2px;
`;