import styled from 'styled-components';
import {TextLink} from '@snsw/react-component-library';
import {colors} from '../../constants/styleConstants';

export const DocumentField = styled(TextLink)`
    width: fit-content;
    display: block;
    line-height: 1.5rem;
    margin-left: 1.5rem;
    margin-bottom: 0px;
    color: ${colors.brand.snswSecondaryBlue}
`;