import styled from 'styled-components';
import {colors, screenSize} from '../../constants/styleConstants';
import {Heading} from '@snsw/react-component-library';

export const InboxDetailHeading = styled(Heading)`
    font-size: 1.75rem; 
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
    @media only screen and (max-width: ${screenSize.tablet}) {
        font-size: 1.625rem;
        line-height: 2.25rem;
    }
`;

export const Sender = styled.div`
    height: 1.5rem;
    color: ${colors.grey.shuttle};
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 1.25rem;
    margin-bottom: 8px;
    @media only screen and (max-width: ${screenSize.mobile}) {
        font-size: 0.875rem;
    }
`;

export const Timestamp = styled.div`
    width: 200px;
    height: 23px;
    color: ${colors.grey.shuttle};
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.125rem;
    @media only screen and (max-width: ${screenSize.mobile}) {
        font-size: 0.75rem;
    }
`;
