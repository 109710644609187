import axios from 'axios';
import utilities from './utilities';
//import {mockInboxDetailData} from '../constants/mockData';

const inboxListConfig = {
    url: '/api/notification-list',
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
};

const getInboxList = async () => {

    return axios.get(inboxListConfig.url, inboxListConfig)
        .then(response => {
            utilities.setCSRFToken(response.headers['x-csrf-token']);
            return response;
        })
        .catch(error => {
            console.log(`caught error: ${error}`);
        });
    //return mockData
};

const getInboxListByPage = async (page, pageSize) => {
    inboxListConfig.url = `/api/notification-list?page=${page}&pageSize=${pageSize}`;
    return axios.get(inboxListConfig.url, inboxListConfig)
        .then(response => {
            utilities.setCSRFToken(response.headers['x-csrf-token']);
            return response;
        })
        .catch(error => {
            console.log(`caught error: ${error}`);
        });
    //return mockData
};

const inboxDetailDataConfig = {
    url: '/api/notification-list',
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
};

const getInboxDetailData = async (props) => {

    inboxDetailDataConfig.url = '/api/notification-list/notifications/' + props.notificationId;

    return axios.get(inboxDetailDataConfig.url, inboxDetailDataConfig)
        .then(response => {
            utilities.setCSRFToken(response.headers['x-csrf-token']);
            console.log('getInboxDetailData response:' + response);
            return response;
        })
        .catch(error => {
            console.log(`caught error: ${error}`);
        });
    //return mockInboxDetailData;
};

export const apiServices = {
    getInboxList,
    getInboxListByPage,
    getInboxDetailData
};