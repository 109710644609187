import React from 'react';
import * as ReactDOM from 'react-dom';
import {ThemeProvider} from 'styled-components';
import {GlobalStyle, theme} from '@snsw/react-component-library';
import {InboxPage} from './inboxPage/InboxPage';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import InboxDetailPage from './inboxDetailPage/InboxDetailPage';
import {CurrentPageProvider} from './CurrentPageContext';
import TagManager from 'react-gtm-module';

export class InboxPageComponent extends HTMLElement {

    constructor() {
        super();
        console.log('Constructing inbox component ');
    }

    connectedCallback() {
        ReactDOM.render(
            <React.StrictMode>
                <ThemeProvider theme={ theme }>
                    <GlobalStyle/>
                    <BrowserRouter>
                        <CurrentPageProvider>
                            <Routes>
                                <Route path='/notifications' element={ <InboxPage/> }/>
                                <Route path='notifications/:notificationId' element={ <InboxDetailPage/> }/>
                            </Routes>
                        </CurrentPageProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </React.StrictMode>
            ,
            document.getElementById('inbox-page-component')
        );
    }

    configGA(){
        const path = window.location.pathname;
        if(path !== '/manage/notification-preferences'){

            TagManager.initialize({gtmId: 'GTM-TMQQTN'});//This is for google 360

            // TODO: Remove gtmIdOfGA3 when GA3 is deprecated.
            const gtmIdOfGA3 = (`${process.env.REACT_APP_HOME_URL}` === 'notification-preferences.service.nsw.gov.au')
                ? 'GTM-N56Q3QK' : 'GTM-W2D98VT';
            TagManager.initialize({gtmId: gtmIdOfGA3});
        }
    }

    disconnectedCallback(){
        ReactDOM.unmountComponentAtNode(this);
    }
}
